/***** Fonts *****/

$font-main: 'Catamaran', sans-serif;
$font-secondary: 'Lato', sans-serif;

/***** Colors *****/

$color-text-main: #7b7b7b;

$color-main: #003657;
$color-secondary: #fff;
$color-third: #474747;
$color-four: #e4e4e4;
$color-five: #84d0f5;


/***** Texts sizes *****/

$text-size-main: 18px;
$text-size-small: 12px;
$text-size-second: 16px;
$text-size-intro: 16px;
$text-size-big: 25px;
$text-size-h1: 80px;
$text-size-h2: 30px;
$text-size-h3: 18px;


/***** transition *****/
.transition-main{
  -webkit-transition: all 200ms ease-in-out;
  -moz-transition: all 200ms ease-in-out;
  -ms-transition: all 200ms ease-in-out;
  -o-transition: all 200ms ease-in-out;
  transition: all 200ms ease-in-out;
}


/***** cookie ****/
$bandeau-cookie-bg: rgba(200, 200, 200, 0.9);
$bandeau-cookie-txt: #000000;
$bandeau-cookie-bouton-bg: #008300;
$bandeau-cookie-bouton-txt: #ffffff;